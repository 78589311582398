import { Component, OnInit } from '@angular/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as adminActions from '../admin/admin.actions';
import * as retailerActions from '../retailer/retailer.actions';
import * as customerActions from '../customer/customer.actions';
import * as fromAdmin from '../admin/admin.reducer';
import * as fromRetailer from '../retailer/retailer.reducer';
import * as fromCustomer from '../customer/customer.reducer';
import { AuthService } from 'src/app/services/auth.service';
import { Roles, User } from 'src/app/models/user.model';
import moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import firebase from 'firebase';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment.prod';
import saveAs from 'save-as';
import axios from 'axios';
import { Statement } from '../models/models';
import { STATEMENT_DATE_FORMAT } from '../utils';
import { NotifyService } from '../shared/notify.service';

let domain = environment.domain;

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html'
})
export class InvoicesComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  customers$: Observable<any>;
  public user: User = null;
  public invoicesData$: Observable<any>;
  public name: string;
  target: string;
  userId: string;
  filter: string;
  loading = false;
  selectedInvoices: Statement[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public auth: AuthService,
    private adminStore: Store<fromAdmin.State>,
    private retailerStore: Store<fromRetailer.State>,
    private customerStore: Store<fromCustomer.State>,
    private notify: NotifyService) { }

  ngOnInit() {
    this.auth.user$.pipe(
      tap(user => {
        this.retailerStore.dispatch(new retailerActions.Query());
        this.customerStore.dispatch(new customerActions.Query(user));
        this.adminStore.dispatch(new adminActions.Query());
      })
    ).subscribe(user => {
      if(user.roles.customer) {
        this.router.navigate(['/landing']);
      }
      this.user = user
    });
    this.route.paramMap.pipe(
      map(params => {
        return {
          id: params.get('id'),
          target: params.get('target')
        };
      })
    ).subscribe(params => {
      this.target = params.target;
      this.userId = params.id;
    });
    this.route.queryParamMap.pipe(
      map(params => {
        return {
          filter: params.get('filter')
        };
      })
    ).subscribe(params => {
      this.filter = params.filter || moment().format(STATEMENT_DATE_FORMAT);
      this.setInvoices();
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  private setInvoices() {
    if (this.target === 'retailer') {
      const retailerStore$ = this.retailerStore.select(fromRetailer.selectAll).pipe(
        map(retailers => {
          return retailers.filter(r => r.id === this.userId)[0];
        })
      );
      const customerStore$ = this.customerStore.select(fromCustomer.selectAll);
      this.invoicesData$ = combineLatest(retailerStore$, customerStore$).pipe(
        map(([retailer, customers]) => {
          this.name = retailer?.name;
          return this.setOrderedInvoices(retailer?.invoices.map(i => {
            const customer = customers.filter(c => c.id === i.customerRef.id)[0];
            i.name = customer?.name;
            return i;
          }));
        })
      );
    } else if (this.target === 'admin') {
      const adminStore$ = this.adminStore.select(fromAdmin.selectAll);
      const retailerStore$ = this.retailerStore.select(fromRetailer.selectAll);
      this.invoicesData$ = combineLatest(adminStore$, retailerStore$).pipe(
        map(([admins, retailers]) => {
          this.name = 'VaultWrx';
          const admin = admins[0];
          return this.setOrderedInvoices(admin?.invoices.map(i => {
            const retailer = retailers.filter(r => r.id === i.retailerRef.id)[0];
            i.name = retailer?.name;
            return i;
          }));
        })
      )
    }
  }

  private setOrderedInvoices(invoices: Statement[]) {
    let orderedInvoices: Statement[] = [];
    if (invoices) {
      orderedInvoices = invoices.filter(i => moment(i.date, STATEMENT_DATE_FORMAT).isSame(moment(this.filter, STATEMENT_DATE_FORMAT))).sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if(a.name < b.name) {
          return -1;
        }
        return 0;
      });
      for (let i = 0; i < orderedInvoices.length; i++) {
        const invoice = orderedInvoices[i];
        invoice.location = invoice.path.split(':')[1]?.split('-')[0] // TODO: Add location name using backend code
        firebase.storage().refFromURL(`gs://${domain}.appspot.com/${invoice.path}`).getDownloadURL().then(url => {
          invoice.path = url;
        }).catch();
      }
    }
    return orderedInvoices;
  }

  public onUpdateSelectedInvoices({ invoices }) {
    this.selectedInvoices = invoices;
  }

  public downloadSelectedInvoices() {
    this.loading = true;
    this.selectedInvoices.forEach((statement, i) => {
      var xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = () => {
        saveAs(xhr.response, `${statement.name}.pdf`);
      }
      xhr.open('GET', statement.path);
      xhr.send();
      if (i === this.selectedInvoices.length - 1) {
        this.loading = false;
      }
    });
  }

  public generateStatements() {
    this.loading = true;
    const payload: {
      retailerId?: string;
      customerId?: string;
      date: string;  // Changed to string to ensure ISO format is preserved
      roles: Roles;
    } = {
      date: moment(this.filter, STATEMENT_DATE_FORMAT).toISOString(), // Format to ISO 8601 string
      roles: this.user.roles
    }
    if (payload.roles.retailer) {
      payload.retailerId = this.user.retailerRef.id;
    }
    if (payload.roles.customer) {
      payload.customerId = this.user.customerRef.id;
    }

    axios.post(`https://us-central1-${environment.domain}.cloudfunctions.net/statements`, payload).then(res => {
      this.setInvoices();
      this.notify.update('Invoices updated successfully', 'success');
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }).catch(err => {
      this.notify.update(err.message, 'danger');
      this.loading = false;
    });
  }
}
